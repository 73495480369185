.add-to-cart {
  color: #fff;
  background: #1abc9c !important; /* #ec5b27 #1abc9c */
  position: fixed;
  font-size: inherit;
  text-decoration: none;
  right: 7.42857142em;
  bottom: 4.1em;
  padding: 6px 12px;
  /*height: 2.563em;*/
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);*/
  /*box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);*/
  box-shadow: none;
  z-index: 99;
  border: 1px solid transparent;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
}

.add-to-cart:not(.active) {
  opacity: 0;
  transform: none;
  -webkit-transform: none;
  pointer-events: none;
}
.add-to-cart.active:hover {
  color: #fff;
  background: #5ed0b9 !important; /* #f18c67 #5ed0b9 */
  text-decoration: none;
  transform: none;
  -webkit-transform: none;
}

.add-to-cart.active {
  box-shadow: none;
}
